<template>

</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Session from '@/handler/session'    

  export default {    
    props: {
      pdf:{
        type: String,
        default: '',
      },
      reference: {
        type: String,
        default: '',        
      },
      registerID: {
        type: Number,
        default: 0,                
      },
      filters: {
        type: Object,
        default: null,
      },
    },    
    data: () => {
      return {
        popup: null,
      }      
    },
    watch: {        
      pdf: function (newQuestion, oldQuestion) {   
        this.openPDF()                           
      },
      reference: function (newQuestion, oldQuestion) {   
        this.openPDF()                           
      },
      registerID: function (newQuestion, oldQuestion) {   
        this.openPDF()                           
      },      
      filters: function (newQuestion, oldQuestion) {   
        this.openPDF()                           
      },
    },
    mounted() {
       this.openPDF()
    },
    destroyed () {      
      this.popup.close()
    },         
    methods: {
      openPDF() {        
        if(this.pdf) {       
          this.popup = window.open(this.pdf,
            "Imprimir",
            "toolbar=no," +
            "scrollbars=no," +
            "location=no," +
            "statusbar=no," +
            "menubar=no," +
            "resizable=0," +
            "width=1000," +
            "height=800," +
            "left=300," +
            "top=100"
          );    
        } else {          
          if(this.reference && this.registerID) {            
            this.generateReports(this.reference, this.registerID, null)
          } else {
            if(this.reference && this.filters) {            
              this.generateReports(this.reference, 0, this.filters)
            }            
          }
        }
      },
      generateReports(reference, registerID=0, filters=null) {
        let loader = this.$loading.show();

        var result = serviceAPI.generate({
          reference: reference,
          regID: registerID,
          filters: filters,
          staff_id: Helper.getStaffId(),
        })

        result.then((response) => {
          loader.hide()
          var data = response.data                        
          this.popup = window.open(data,
            "Imprimir",
            "toolbar=no," +
            "scrollbars=no," +
            "location=no," +
            "statusbar=no," +
            "menubar=no," +
            "resizable=0," +
            "width=1000," +
            "height=800," +
            "left=300," +
            "top=100"
          );                      
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });             
      }      
    }
  }
</script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  generate(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports/generate/pdf" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
